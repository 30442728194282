
import { commonProps } from '../../utils/prismic';
import { pushHookEvent } from '~/plugins/gtm';
import { LAYOUT_ACTION_FIXED_BUTTON } from '~/store/layout';

export default {
  props: commonProps,
  data() {
    return {
      disabled: true,
      activeNotification: {},
      activeExtraStep: {},
      loanTrackingData: {
        main_residency: 'résidence principale',
        renting_investment: 'investissement locatif',
        secondary_residency: 'résidence secondaire',
        new: 'nouveau prêt',
        change: "changement d'assurance",
        bourquin: 'plus de 12 mois',
        hamon: 'moins de 12 mois',
      },
    };
  },
  // Show notification of first input option & check first input option - emprunteur only
  mounted() {
    this.setFixedButton(this.slice);
    if (this.slice.data.type === 'sante') {
      return;
    }

    const loanInputs = this.slice.data.component.inputs;

    loanInputs
      .filter((input) => input.inputs)
      .forEach((input) => {
        const firstInput = input.inputs[0];

        const { notificationId, extraStepId } = firstInput || {};

        if (notificationId) {
          const notification = this.slice.data.notifications?.find(
            (item) => item.notificationId === notificationId
          );
          if (notification)
            this.activeNotification = { name: input.name, data: notification };
        }

        if (extraStepId) {
          const extraStep = this.slice.data.extraSteps?.find(
            (item) => item.extraStepId === extraStepId
          );
          if (extraStep)
            this.activeExtraStep = { name: input.name, data: extraStep };
        }
      });

    const form = this.$children[0]?.$refs?.form;
    const formData = form ? new FormData(form) : null;

    if (formData) {
      this.checkValidity(formData);
    }
  },
  methods: {
    setFixedButton(slice) {
      const { hasFixedButton, cta } = slice.data;
      const ctaLabel = slice.data.component.ctaLabel;
      const storeValue = hasFixedButton
        ? {
            cta,
            label: ctaLabel,
            alwaysVisible: false,
          }
        : null;
      this.$store.dispatch(`layout/${LAYOUT_ACTION_FIXED_BUTTON}`, storeValue);
    },
    handleSubmitSante(formData) {
      const url = new URL(this.slice.data.submitUrl);
      const gclid = this.$route?.query?.gclid;
      const data = {};

      if (gclid) {
        url.searchParams.append('gclid', gclid);
      }

      for (const [key, value] of formData.entries()) {
        url.searchParams.append(key, this.slice.data.valueToData[key][value]);
        data[key] = this.slice.data.valueToData[key][value];
      }

      pushHookEvent(this, data, 'sante');
      window.open(url.toString(), '_blank').focus();
    },
    handleSubmitEmprunteur(formData) {
      const url = new URL(this.slice.data.submitUrl);
      const gclid = this.$route?.query?.gclid;
      const data = {};

      if (gclid) {
        url.searchParams.append('gclid', gclid);
      }

      for (const [key, value] of formData.entries()) {
        url.searchParams.append(key, value);
        data[key] = this.loanTrackingData[value.toLowerCase()];
      }

      pushHookEvent(this, data, 'emprunteur');
      window.open(url.toString(), '_blank').focus();
    },
    handleChangeEmprunteur({ name, input }, formData) {
      this.checkValidity(formData);
      const { notificationId, extraStepId } = input;

      if (name === this.activeNotification.name) {
        this.activeNotification = {};
      }

      if (name === this.activeExtraStep.name) {
        this.activeExtraStep = {};
      }

      if (notificationId) {
        const notification = this.slice.data.notifications?.find(
          (item) => item.notificationId === notificationId
        );
        if (notification)
          this.activeNotification = { name, data: notification };
      }

      if (extraStepId) {
        const extraStep = this.slice.data.extraSteps?.find(
          (item) => item.extraStepId === extraStepId
        );

        if (extraStep) this.activeExtraStep = { name, data: extraStep };
      }
    },
    checkValidity(formData) {
      const inputs = [
        ...this.slice.data.component.inputs?.filter(
          ({ defaultValue }) => !defaultValue
        ),
        ...this.slice.data.component.staticExtraSteps,
      ];

      const isValid = inputs.every((input) => !!formData.get(input?.name));

      if (isValid) {
        this.disabled = false;
      }
    },
  },
};
